<template>
  <div class="container">
    <div class="search-top">
      <el-form :inline="true">
        <el-form-item label="订单状态：">
          <el-select
            v-model="searchData.status"
            size="medium"
            style="width: 120px"
            @change="getSearchData"
          >
            <el-option
              v-for="item of orderStatus"
              :value="item.value"
              :label="item.label"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用药周期：">
          <el-select
            v-model="searchData.medicationPeriod"
            size="medium"
            style="width: 100px"
            @change="getSearchData"
          >
            <el-option
              v-for="item of cycle.options"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付时间：">
          <el-date-picker
            style="width: 140px"
            value-format="yyyy-MM-dd"
            size="medium"
            v-model="searchData.startTime"
            type="date"
            placeholder="开始日期"
            :picker-options="startTime"
          ></el-date-picker>
          -
          <el-date-picker
            style="width: 140px"
            value-format="yyyy-MM-dd"
            size="medium"
            v-model="searchData.endTime"
            type="date"
            placeholder="结束日期"
            :picker-options="endTime"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input
            @keyup.enter.native="getSearchData"
            clearable
            :placeholder="keywordTypeList[0].options[searchData.keywordType - 1].placeholder"
            v-model="searchData.keyword"
            style="width: 250px"
          >
            <el-select style="width: 110px" slot="prepend" v-model="searchData.keywordType">
              <el-option-group
                :key="group.label"
                :label="group.label"
                v-for="group in keywordTypeList"
              >
                <el-option
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-for="item in group.options"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="实付金额：">
          <el-select
            v-model="searchData.payAmount"
            placeholder="请选择"
            style="width: 120px"
            @change="getSearchData"
          >
            <el-option
              v-for="item in payAmounts.list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="打印状态：">
          <el-select
            v-model="searchData.printFlag"
            placeholder="请选择"
            style="width: 120px"
            @change="getSearchData"
          >
            <el-option
              v-for="item in prints.list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-checkbox
            v-model="searchData.incompleteAddresses"
            label="显示未完善地址订单"
            border
            @change="getSearchData"
          />
        </el-form-item>
        <!-- <el-form-item label=" ">
          <el-select v-model="searchData.specificationType">
            <el-option v-for="item of drugStatus" :key="item.key" :value="item.key" :label="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="getSearchData" size="medium" type="primary" :disabled="loading">
            确定
          </el-button>
          <el-button
            type="primary"
            @click="clearSearchData"
            size="medium"
            class="margin-left-right-10px"
            :disabled="loading"
          >
            清空筛选条件
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="exportSearchData" size="medium" type="success">导出订单</el-button>
        </el-form-item>
        <!--<el-form-item>
          <prescription-print :print-list="selectData" type="primary" />
        </el-form-item>
        <el-form-item>
          <el-button @click="batchPrintOrder" size="medium" type="primary">批量打印订单</el-button>-->
        <!--<el-button @click="batchPrintLable" size="medium" type="primary">批量打印标签</el-button>-->
        <!--<el-button @click="batchPrintEOrder" size="medium" type="success">
            批量打印电子面单
          </el-button>-->
        <!--<el-button
            @click="batchPrintCollectOrder"
            size="medium"
            type="danger"
            :disabled="collectWaybillDisabledComputed"
          >
            打印到付电子面单
          </el-button>
          <el-button @click="onAdvicePrint" size="medium" type="primary" class="advice-button">
            打印用药指导
          </el-button>-->
        <!-- <el-button
        @click="scanBarcode"
        size="medium"
        type="primary"
        class="btn_purple">扫描条形码发货</el-button> -->
        <!-- <el-form-item label="设置使用快递：" style="float:right;">
          <el-select v-model="expressType">
            <el-option-group :key="group.label" :label="group.label" v-for="group in expressTypeList">
              <el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in group.options">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item> -->
        <!--<el-form-item label="" style="float: right">
          <el-select
            v-model="printState.printerSelectCode"
            placeholder="请选择"
            @change="onPrinterChange"
          >
            <el-option
              v-for="item in printState.printerList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>-->
      </el-form>
    </div>

    <div>
      <el-table
        ref="data-table"
        v-loading="loading"
        element-loading-text="拼命加载中"
        :cell-style="cellStyle"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        border
        height="595"
        :key="tableKey"
        style="width: 100%; font-size: 0.14rem"
        @selection-change="handleSelectionChange"
        v-watermark
      >
        <el-table-column
          type="selection"
          fixed="left"
          label="全选"
          align="center"
          width="45"
        ></el-table-column>
        <!-- <el-table-column
          prop="askNo"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="问诊编号"
          fixed="left"
          width="140"
        >
        </el-table-column> -->
        <el-table-column
          prop="orderNo"
          label="订单编号"
          align="center"
          width="170"
          fixed="left"
          :show-overflow-tooltip="showOverflow"
        >
          <template slot-scope="scope">
            <span
              style="color: #409eff; cursor: pointer"
              @click="lookOrderDetails(scope.$index, scope.row)"
            >
              {{ scope.row.orderNo }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="userNo"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="用户编号"
          width="100"
        >
        </el-table-column> -->
        <el-table-column
          prop="userMemberName"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="就诊人"
          width="100"
        ></el-table-column>
        <!-- <el-table-column
          prop="items"
          align="center"
          :show-overflow-tooltip="showOverflow"
          width="250"
          label="订单商品"
        >
          <template slot-scope="scope">
            <p
              style="text-align: left"
              v-for="(item, index) of scope.row.items"
              :key="index"
            >
              {{ ++index }}、{{ item.genericName }}({{
                item.drugSpecifications
              }})*{{ item.quantity }}
            </p>
          </template>
        </el-table-column> -->

        <!-- <el-table-column
          prop="payAmount"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="订单金额"
          width="80"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="totalFee"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="药品金额"
          width="80"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="discount"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="优惠金额"
          width="80"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="shippingFee"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="运费"
          width="80"
        >
        </el-table-column> -->
        <el-table-column
          prop="receiverName"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="收货人"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="receiverPhone"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="收货人电话"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="receiverAddress"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="收货地址"
        >
          <template #default="scope">
            {{ scope.row.receiverAddress }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="payTime"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="支付时间"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.payTime | dataFormate }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="sendTime"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="发货时间"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.sendTime | dataFormate }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="用药周期" width="80px">
          <template #default="scope">{{ onCycleFilter(scope.row) }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="医生"
          width="100px"
          prop="doctorName"
          :show-overflow-tooltip="showOverflow"
        ></el-table-column>
        <el-table-column
          align="center"
          label="医助"
          width="100px"
          prop="assistantName"
          :show-overflow-tooltip="showOverflow"
        ></el-table-column>
        <el-table-column align="center" label="备注" width="80">
          <template #default="scope">
            <el-button type="text" @click="onRemarksShow(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="payTime"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="支付时间"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.payTime | dataFormate }}
          </template>
        </el-table-column>
        <el-table-column
          prop="payTime"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="实付金额"
          width="100"
          #default="{ row }"
        >
          <span :style="row.payAmount <= 0 ? 'color: #f00' : ''">{{ row.payAmount }}</span>
        </el-table-column>
        <el-table-column
          prop="payTime"
          align="center"
          label="处方流转信息"
          width="240"
          #default="{ row }"
        >
          <div
            v-if="row.syncFlag || row.syncMsg || row.dmStoreName || row.syncTime || row.thirdMsg"
          >
            <p>
              流转状态：
              <span v-if="row.syncFlag == 0">成功</span>
              <span v-if="row.syncFlag == 1">
                失败
                <el-tooltip placement="top">
                  <div slot="content">
                    {{ row.syncMsg }}
                  </div>
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </span>
            </p>
            <p>流转药房：{{ row.dmStoreName }}</p>
            <p>流转时间：{{ row.syncTime }}</p>
            <p>处方状态：{{ row.thirdMsg }}</p>
          </div>
          <div v-else>-</div>
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          :show-overflow-tooltip="showOverflow"
          label="订单状态"
          width="130"
          fixed="right"
        >
          <template slot-scope="scope">
            <!--<span>{{scope.row.status | formateOrderStatus}}</span>-->
            <span v-if="scope.row.status == 4" class="to-be-accepted">待发货</span>
            <span v-if="scope.row.status == 5" class="have-in-hand">待签收</span>
            <span v-if="scope.row.status == 6" class="success">已完成</span>
            <span v-if="scope.row.status == 7" class="failed">已退货</span>
            <span v-if="scope.row.status == 8" class="failed">已退款</span>
            <span v-if="scope.row.status == 10" class="have-in-handone">
              {{ `已签收(${scope.row.deliverType == 2 ? "自取" : "物流"})` }}
            </span>
            <span v-if="scope.row.status == 12" class="success">面单已打印</span>
            <span v-if="scope.row.status == 13" class="to-be-accepted">待自取</span>
            <span v-if="scope.row.status == 14" class="to-be-accepted">待揽收</span>
          </template>
        </el-table-column>
        <el-table-column label="打印状态" prop="printFlag" align="center">
          <template slot-scope="scope">
            {{ (prints.list.find(item => item.value === scope.row.printFlag) || {}).label || "-" }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="150" fixed="right">
          <template slot-scope="scope">
            <!-- v-if="scope.row.status == 4 && env == 'development'" -->
            <template v-if="scope.row.status != 7 && scope.row.status != 8">
              <div>
                <!-- <el-button class="btn_purple" size="mini" type="primary"
                  @click="printOrder(scope.$index, scope.row)">打印订单</el-button> -->
                <!-- <el-button
              class="btn_purple"
              size="mini"
              type="primary"
              @click="printLable(scope.$index, scope.row)"
              >打印标签</el-button
            > -->
                <el-button
                  size="mini"
                  type="primary"
                  @click="onDeliverConfirm(scope.row)"
                  v-if="scope.row.status == 13"
                >
                  确认用户已自取
                </el-button>
                <!-- <el-button class="btn_purple" size="mini" type="primary" @click="printEOrder(scope.$index, scope.row)"
                  v-else-if="scope.row.deliverType != 2 && scope.row.receiverName && scope.row.receiverAddress">打印电子面单</el-button> -->
              </div>
            </template>
            <div>
              <el-button
                v-if="scope.row.status == 4"
                size="mini"
                type="primary"
                @click="addLogistics(scope.$index, scope.row)"
              >
                添加物流
              </el-button>
              <el-button
                size="mini"
                type="primary"
                @click="lookOrderDetails(scope.$index, scope.row)"
              >
                查看订单
              </el-button>
              <!-- <el-button
              v-if="scope.row.status == 5 || scope.row.status == 6"
              size="mini"
              type="primary"
              @click="lookLogistics(scope.$index, scope.row)"
              >查看物流</el-button
            >
            </div> -->
              <el-button
                v-if="scope.row.deliverType != 2 && scope.row.status != 4"
                size="mini"
                type="primary"
                @click="lookLogistics(scope.$index, scope.row)"
              >
                查看物流
              </el-button>
              <el-button
                size="mini"
                type="primary"
                @click="onSendRequest(scope.row)"
                v-if="scope.row.syncFlag == 1"
              >
                再次发起流转
              </el-button>
              <!--<el-button
                v-if="scope.row.deliverType !== 2 && scope.row.status === 14"
                size="mini"
                type="danger"
                @click="onDeliverCancel(scope.row)"
              >
                取消发货
              </el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="total > 0">
        <el-pagination
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="basicParam.pageNum"
          :page-sizes="[10, 20, 30, 40, 100]"
          :page-size="basicParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <lookLogisticsDialog
      v-if="showDialog"
      v-on:closeDialog="closeDialog"
      :logisticsInfo="logisticsInfo"
    ></lookLogisticsDialog>
    <lookOrderDialog
      v-if="showLookOrderDialog"
      v-on:closeLookOrderDialog="closeLookOrderDialog"
      :orderInfo="orderInfo"
      v-on:reflushList="reflushList"
      @init="lookOrderDetailsInit"
    ></lookOrderDialog>
    <printOrderDialog
      v-on:closePrintOrderDialog="closePrintOrderDialog"
      v-on:doPrintOrderDetail="doPrintOrderDetail"
      :printOrderList="printOrderList"
      v-if="showPrintOrderDialog"
    ></printOrderDialog>
    <printLableDialog
      v-on:closePrintLableDialog="closePrintLableDialog"
      v-on:doPrintLableDetail="doPrintLableDetail"
      :printLableList="printLableList"
      v-if="showPrintLableDialog"
    ></printLableDialog>
    <el-dialog title="添加物流信息" :visible.sync="dialogVisible" width="600px">
      <span>
        <el-form :model="logisticsParam" label-width="100px" v-watermark>
          <el-form-item label="订单编号">
            <el-input v-model="logisticsParam.orderNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="收货人">
            <el-input v-model="logisticsParam.receiverName" disabled></el-input>
          </el-form-item>
          <el-form-item label="物流公司名称">
            <el-select v-model="logisticsParam.shippingComp" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流编号">
            <el-input
              ref="autofocus"
              v-model="logisticsParam.logisticsNo"
              @keyup.enter="saveLogistics"
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveLogistics">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="扫描条形码回显"
      :visible.sync="showScanBarDialog"
      @closed="showScanBarDialog = false"
      width="600px"
    >
      <input
        type="text"
        id="barcode_input"
        v-model="scanBarCodeValue"
        autocomplete="off"
        @keyup.enter="confirmLogistics"
        class="barcode_input"
      />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showScanBarDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmLogistics">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="查看备注"
      :visible.sync="remarks.show"
      width="500px"
      custom-class="custom-dialog"
    >
      <div class="remarks-dialog" v-watermark>
        <remarks-index ref="remarks" />
      </div>
    </el-dialog>

    <audio v-show="false" id="audioSuccess">
      <source src="../../../static/audioSuccess.mp3" />
    </audio>
    <audio v-show="false" id="audioLoad">
      <source src="../../../static/audioLoad.mp3" />
    </audio>
    <audio v-show="false" id="audioFail">
      <source src="../../../static/audioFail.mp3" />
    </audio>
    <audio v-show="false" id="audioOut">
      <source src="../../../static/audioOut.mp3" />
    </audio>

    <!-- 用药指导打印 -->
    <AdvicePrint ref="AdvicePrintRef" />
  </div>
</template>

<script>
import moment from "moment";
import lookLogisticsDialog from "./dialog/lookLogisticsDialog1.vue";
import lookOrderDialog from "./dialog/lookOrderDialog.vue";
import printOrderDialog from "./dialog/printOrderDialog.vue";
import printLableDialog from "./dialog/printLableDialog.vue";
import consigneeTableVue from "./dialog/table/consigneeTable.vue";
import { downloadExport } from "@/common/downLoadFile.js";
import print from "print-js";
import remarksIndex from "../remarks/index.vue";
import { Loading, MessageBox, Message } from "element-ui";
import prescriptionPrint from "../myPrescription/dialog/print.vue";
import AdvicePrint from "@/componets/commodityOrders/advice/print.vue";
export default {
  name: "commodityOrdersIndex",
  data() {
    return {
      env: "",
      tableKey: null,
      orderInfo: {
        //基本信息
        essentialInformation: [],
        //发票信息
        invoiceInformation: [],
        //收货人信息
        consignee: [],
        //商品信息
        commodityInfo: [],
        //操作信息
        operationalInformation: [],
        //全部信息
        allInfomation: {},
      },
      printOrderList: [],
      printLableList: [],
      options: [],
      orderStatus: [],
      commodityDetails: {},
      showDialog: false,
      showLookOrderDialog: false,
      showScanBarDialog: false,
      showPrintOrderDialog: false,
      showPrintLableDialog: false,
      scanBarCodeValue: "",
      loading: true,
      total: 0,
      sendRequest: false, //再次发起物流请求
      showOverflow: true,
      tableData: [],
      isClickYes: false,
      searchParam: {},
      basicParam: {
        pageNum: 1,
        pageSize: 10,
      },
      keywordTypeList: [
        {
          options: [
            {
              value: "1",
              label: "就诊人",
              placeholder: "请输入就诊人",
            },
            {
              value: "2",
              label: "问诊编号",
              placeholder: "请输入问诊编号",
            },
            {
              value: "3",
              label: "收货人",
              placeholder: "请输入收货人",
            },
            {
              value: "4",
              label: "订单编号",
              placeholder: "请输入订单编号",
            },
          ],
        },
      ],
      expressTypeList: [
        {
          options: [
            {
              value: "shunfeng",
              label: "顺丰速运",
            },
            {
              value: "jd",
              label: "京东快递",
            },
          ],
        },
      ],
      expressType: "shunfeng",
      searchData: {
        status: "",
        startTime: "",
        endTime: "",
        keyword: "",
        keywordType: "1",
        medicationPeriod: "",
        incompleteAddresses: false,
        specificationType: "",
        payAmount: "",
        printFlag: "",
      },
      logisticsInfo: "",
      dialogVisible: false,
      logisticsParam: {
        orderNo: "",
        receiverName: "",
        logisticsNo: "",
        shippingComp: "",
        shippingCompName: "",
        userNo: "",
      },
      res: "",

      startTime: {
        disabledDate: time => {
          if (this.searchData.endTime) {
            return time.getTime() > new Date(this.searchData.endTime).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      endTime: {
        disabledDate: time => {
          if (this.searchData.startTime) {
            return (
              time.getTime() > Date.now() ||
              time.getTime() < new Date(this.searchData.startTime).getTime() - 86400000
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },

      endTimeout: null,
      selectData: [],

      retrievalLoading: false,

      remarks: {
        show: false,
      },
      cycle: {
        // 用药周期
        options: [],
      },
      drugStatus: [
        {
          key: "",
          value: "全部",
        },
        {
          key: 1,
          value: "新",
        },
        {
          key: 0,
          value: "旧",
        },
      ],
      printState: {
        printerKey: "__printer__",
        printerSelectCode: "",
        printerList: [],
      },
      payAmounts: {
        list: [
          {
            label: "全部",
            value: "",
          },
          {
            label: "0元支付",
            value: "0",
          },
          {
            label: "非0元支付",
            value: "1",
          },
        ],
      },
      prints: {
        list: [
          {
            label: "未打印",
            value: 0,
          },
          {
            label: "已打印",
            value: 1,
          },
        ],
      },
    };
  },
  computed: {
    // 到付面单禁用
    collectWaybillDisabledComputed() {
      if (this.selectData.length) {
        return !this.selectData.every(
          row => Number(row.payAmount) === 0 && [4, 13].includes(row.status)
        );
      }
      return true;
    },
    noZeroWaybillDisabledComputed() {
      if (this.selectData.length) {
        return !this.selectData.every(
          row => Number(row.payAmount) > 0 && [4, 13].includes(row.status)
        );
      }
      return true;
    },
  },
  components: {
    lookLogisticsDialog,
    lookOrderDialog,
    printOrderDialog,
    printLableDialog,
    remarksIndex,
    prescriptionPrint,
    AdvicePrint,
  },
  watch: {
    "$store.state.messageWebSocket.ydOrderListRefresh"(newVal, oldVal) {
      if (newVal) {
        this.detailMap();
        this.getCommodityOrderList();
        let messageWebSocket = this.$store.state.messageWebSocket;
        messageWebSocket.ydOrderListRefresh = oldVal;
        this.$store.commit("setMessageWebSocket", messageWebSocket);
      }
    },
  },
  methods: {
    onAdvicePrint() {
      if (!this.selectData.length) {
        Message.error("请选择订单");
        return false;
      }
      if (this.$refs.AdvicePrintRef) {
        this.$refs.AdvicePrintRef.init(this.selectData.map(row => row.orderNo));
      }
    },
    onDeliverCancel(row) {
      MessageBox.confirm("是否确认取消发货?", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          const loading = Loading.service({
            lock: true,
            text: "取消发货...",
            background: "rgba(255, 255, 255, 0.9)",
          });
          this.$axios
            .get("/order-service/drugmg/order/cancelELabel", {
              params: {
                orderNo: row.orderNo,
              },
            })
            .then(({ data: { code, message } }) => {
              if (code === 0) {
                Message.success("取消发货成功");
                this.getCommodityOrderList();
              } else {
                Message.error(message || "取消发货失败");
              }
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    onCycleGet() {
      //用药周期获取
      this.$axios
        .get("/misc-service/typeInfo/detail", {
          params: {
            typeCode: "TM_PERIOD_TYPE",
          },
        })
        .then(({ data }) => {
          if (data.code == 0) {
            this.cycle.options = [{ value: "全部", key: "" }].concat(data.data.list || []);
          }
        });
    },
    //再次发起流转请求
    onSendRequest(row) {
      var _this = this;
      if (!this.sendRequest) {
        this.sendRequest = true;

        let params = {
          prescriptionNoList: [row.prescriptionNo],
        };
        const loading = this.$loading({
          lock: true,
          text: "拼命流转中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$axios({
          method: "POST",
          url: this.$APIURL.DRUGINFOS_REINITIATEFLOW,
          data: params,
        })
          .then(res => {
            loading.close();
            if (res.data.code == 0) {
              this.$respone.success("操作成功");
              this.getCommodityOrderList();
              this.tableKey = Math.random();
              this.sendRequest = false;
            } else {
              this.$respone.error(res.data.message);
              this.sendRequest = false;
            }
          })
          .catch(err => {
            loading.close();
            _this.$respone.error("发生异常");
            this.sendRequest = false;
          });
      }
    },
    onCycleChange(command) {
      // 用药周期切换
      this.searchData.medicationPeriod = command;
      this.basicParam.pageNum = 1;
      this.getCommodityOrderList();
    },
    onCycleFilter(row) {
      if (row.medicationPeriod == 5) {
        return row.mpValue ? `${row.mpValue}天` : "";
      } else {
        let list = this.cycle.options;
        if (list.length) {
          for (let i = 0; i < list.length; i++) {
            if (list[i].key == row.medicationPeriod) {
              return `${list[i].remark}天`;
            }
          }
        }
      }

      return "";
    },
    getCommodityOrderList() {
      this.loading = true;
      let _this = this;
      this.searchParam = {};
      this.searchParam = Object.assign({}, this.basicParam);
      if (_this.isClickYes) {
        this.searchParam = Object.assign(this.searchParam, this.searchData);
      }
      const send = JSON.parse(JSON.stringify(this.searchParam));
      if (send.startTime) send.startTime = send.startTime + " 00:00:00";
      else delete send.startTime;
      if (send.endTime) send.endTime = send.endTime + " 23:59:59";
      else delete send.endTime;
      send.TM_PERIOD_TYPE = this.cycle.active;
      this.$axios
        .get(this.$APIURL.ORDER_ITEMLIST, {
          params: {
            ...send,
            incompleteAddresses: !send.incompleteAddresses,
            payAmountMin:
              this.searchData.payAmount === ""
                ? null
                : this.searchData.payAmount === "0"
                ? 0
                : 0.01,
            payAmountMax: this.searchData.payAmount === "0" ? 0 : null,
          },
        })
        .then(response => {
          if (response.data.code == 0) {
            if (response.data.data != null) {
              _this.tableData = response.data.data.list;
              this.total = response.data.data.total;
            } else {
              this.total = 0;
              _this.tableData = [];
            }
          }
        })
        .catch(error => {
          _this.$respone.error(_this.$respone.catchMsg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prevClick(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.getCommodityOrderList();
    },
    nextClick(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.getCommodityOrderList();
    },
    currentChange(currentPage) {
      this.basicParam.pageNum = currentPage;
      this.tableData = [];
      this.getCommodityOrderList();
    },
    sizeChange(size) {
      this.basicParam.pageSize = size;
      this.getCommodityOrderList();
    },
    cellStyle({ row, column, rowIndex, columnIdex }) {
      let style = { height: "0.4rem" };
      return style;
    },
    headerCellStyle({ row, column, rowIndex, columnIdex }) {
      let style = { background: "#f1f4fa", height: "0.6rem" };
      return style;
    },
    closeDialog(data, refresh) {
      this.showDialog = data;
    },
    closeLookOrderDialog(data) {
      this.showLookOrderDialog = data;
    },
    closePrintOrderDialog(data) {
      this.showPrintOrderDialog = data;
    },
    closePrintLableDialog(data) {
      this.showPrintLableDialog = data;
    },
    getSearchData() {
      this.isClickYes = true;
      this.basicParam.pageNum = 1;
      this.tableData = [];
      this.getCommodityOrderList();
    },
    clearSearchData() {
      this.isClickYes = false;
      this.basicParam.pageNum = 1;
      this.searchData.status =
        this.searchData.startTime =
        this.searchData.endTime =
        this.searchData.keyword =
        this.searchData.userName =
          "";
      this.searchData.incompleteAddresses = false;
      this.searchData.specificationType = "";
      this.searchData.payAmount = "";
      this.searchData.printFlag = "";
      this.getCommodityOrderList();
    },
    lookLogistics(index, row) {
      this.$axios
        //.get(this.$APIURL.LOOK_LOGISTIC, {
        //  params: { logisticsId: row.logisticsId },
        //})
        .get(this.$APIURL.LOOK_LOGISTIC + "/" + row.orderNo)
        .then(res => {
          if (res.data.code == 0 && res.data.data != null) {
            //this.logisticsInfo = res.data.data;
            this.logisticsInfo = JSON.parse(res.data.data);
            console.log(this.logisticsInfo, "logisticsInfo");
            this.showDialog = true;
          } else {
            this.$respone.warning("暂时没有物流信息！");
          }
        })
        .catch(err => {
          this.$respone.error("网络异常，请稍后重试！");
        });
    },
    lookOrderDetails(index, row) {
      this.$axios
        .get(this.$APIURL.LOOK_ORDER_DETAILS, {
          params: { orderNo: row.orderNo, status: 4 },
        })
        .then(res => {
          if (res.data.code == 0 && res.data.data != null) {
            var data = res.data.data;
            this.orderInfo.allInfomation = res.data.data;
            this.orderInfo.essentialInformation = [];
            this.orderInfo.essentialInformation.push({
              orderNo: data.orderNo,
              drugStoreName: data.drugStoreName,
              shippingCompName: data.shippingCompName,
              logisticsNo: data.logisticsNo,
              couponName: data.couponName,
            });

            this.orderInfo.consignee = [];
            this.orderInfo.consignee.push({
              receiverName: data.receiverName,
              receiverPhone: data.receiverPhone,
              receiverAddress: data.receiverAddress,
            });

            this.orderInfo.invoiceInformation = [];
            if (typeof res.data.data.invoice == "string" && res.data.data.invoice != null) {
              // JSON.parse(res.data.data.invoice)
              this.orderInfo.invoiceInformation.push(res.data.data.invoice);
            }
            this.showLookOrderDialog = true;
          } else {
            this.$respone.error(res.data.message);
          }
        })
        .catch(err => {
          this.$respone.error("网络异常，请稍后重试！");
        });
    },
    lookOrderDetailsInit(row) {
      this.getCommodityOrderList();
      this.lookOrderDetails(0, row);
    },
    reflushList() {
      this.getCommodityOrderList();
    },
    addLogistics(index, row) {
      // this.logisticsParam.shippingComp = this.options[0].value
      // 判断是否可以添加物流
      let params = { prescriptionNo: row.prescriptionNo };
      console.log(this.$APIURL.LOGISTICS_ADD_HAS, "ssssss");
      console.log(this.options, "sdddadadada");
      this.$axios.get(this.$APIURL.LOGISTICS_ADD_HAS, { params: params }).then(res => {
        if (res.data.code == 0) {
          this.logisticsParam.userNo = row.userNo;
          this.logisticsParam.orderNo = row.orderNo;
          this.logisticsParam.receiverName = row.receiverName;
          this.logisticsParam.logisticsNo =
            this.logisticsParam.shippingComp =
            this.logisticsParam.shippingCompName =
              "";
          this.dialogVisible = true;
          this.$nextTick(() => {
            // this.logisticsParam.shippingComp = this.options[0].value
            // 默认选中顺丰
            if (this.options && this.options.length > 0) {
              //let index = this.options.findIndex(item => item.value == 1);
              let index = this.options.findIndex(item => item.value == 3); //顺丰是3
              if (index > -1) {
                this.logisticsParam.shippingComp = this.options[index].value;
              } else {
                this.logisticsParam.shippingComp = this.options[0].value;
              }
            }
            this.$refs["autofocus"].focus();
          });
        } else {
          this.$respone.error(res.data.message);
        }
      });
    },
    saveLogistics() {
      console.log(this.logisticsParam);
      if (this.logisticsParam.logisticsNo == "") {
        this.$message.warning("请先录入物流编号");
        return false;
      }
      if (this.logisticsParam.shippingComp) {
        let index = this.options.findIndex(item => item.value == this.logisticsParam.shippingComp);
        this.logisticsParam.shippingCompName = this.options[index].label;
      }
      let data = [this.logisticsParam];
      this.$axios
        .post(this.$APIURL.DRUGINFOS_ADDLOGISTIC, data)
        .then(res => {
          if (res.data.code == 0) {
            this.$respone.success("添加物流成功！");
            this.dialogVisible = false;
            this.getCommodityOrderList();
          } else {
            this.$respone.error(res.data.message);
          }
        })
        .catch(err => {
          this.$respone.error(this.$respone.catchMsg);
        });
    },
    // 快递公司
    detailMap() {
      this.$axios
        .get(this.$APIURL.DRUGINFOS_DETAILMAP, {
          params: { typeCode: "LOGISTICS_TYPE" },
        })
        .then(res => {
          if (res.data.code == 0) {
            //let obj = res.data.data.detailMap;
            let obj = res.data.data;
            //for (let i in obj) {
            //  let o = {};
            //  o.label = i;
            //  o.value = obj[i];
            //  this.options.push(o);
            //}
            for (let i in obj) {
              let o = {};
              o.label = obj[i].companyName;
              o.value = obj[i].id;
              this.options.push(o);
            }
          }
        })
        .catch(err => {});
    },

    // 扫码枪录入物流编号
    inputSaoMa(val) {
      // if(val == '') return false
      // this.saveLogistics()
      // clearTimeout(this.endTimeout)
      // this.endTimeout = null
      // this.endTime = setTimeout(() => {
      //   if(this.logisticsParam.logisticsNo == val){
      //     clearTimeout(this.endTimeout)
      //     this.saveLogistics()
      //   }
      // }, 500)
    },
    handleSelectionChange(value) {
      this.selectData = value;
    },
    exportSearchData() {
      if (this.selectData.length != 0) {
        let orderNoList = [];
        this.selectData.forEach(item => {
          orderNoList.push(item.orderNo);
        });
        orderNoList = orderNoList.join(",");
        let data = {
          orderNoList,
          drugStoreNo: this.$store.state.userInfo.refUserNo,
        };
        downloadExport({
          params: data,
          url: this.$APIURL.EXPORT_SELECT_ORDERLIST,
          method: "get",
          name: "商品订单",
          fullName: true,
        });
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "请先勾选需要导出的订单",
          type: "warning",
        });
      }
    },
    async batchPrintLable() {
      if (this.selectData.length != 0) {
        let lableList = await Promise.all(
          this.selectData.map(item => {
            return this.$axios.get(this.$APIURL.LOOK_ORDER_DETAILS, {
              params: { orderNo: item.orderNo, status: 4 },
            });
          })
        );
        let printList = [];
        lableList.forEach(item => {
          const list = item.data.data.prescriptionDetailList.map(Item => {
            return { ...Item, userMemberName: item.data.data.userMemberName };
          });
          printList = [...printList, ...list];
        });
        this.$confirm("确定打印 " + lableList.length + " 张订单标签？", "批量打印订单标签", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(() => {
          this.printLableList = printList;
          this.showPrintLableDialog = true;
        });
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "请先勾选需要打印标签的订单",
          type: "warning",
        });
      }
    },
    batchPrintOrder() {
      if (this.selectData.length != 0) {
        let orderNoList = [];
        this.selectData.forEach(item => {
          orderNoList.push(item.orderNo);
        });
        this.$confirm(`确定打印 ${orderNoList.length} 张订单详情？`, "批量打印订单", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }).then(async () => {
          this.printOrderList = [];
          let loading = Loading.service({
            background: "rgba(0,0,0,.8)",
            text: "打印数据加载中，请等待...",
          });
          for (let i = 0; i < orderNoList.length; i++) {
            await this.getOrderDetailToPrintOrderList(orderNoList[i]);
          }
          loading.close();
          this.showPrintOrderDialog = true;
        });
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "请先勾选需要打印的订单",
          type: "warning",
        });
      }
    },
    printOrder(index, row) {
      this.printOrderList = [];
      this.getOrderDetailToPrintOrderList(row.orderNo);
      this.showPrintOrderDialog = true;
    },
    printLable(index, row) {
      let lableList = [];
      lableList.push({
        patient_name: row.userMemberName,
        order_no: row.orderNo,
        order_date: row.payTime,
      });
      this.printLableList = lableList;
      this.showPrintLableDialog = true;
    },
    async getOrderDetailToPrintOrderList(orderNo) {
      await this.$axios
        .get(this.$APIURL.LOOK_ORDER_DETAILS, {
          params: { orderNo: orderNo, status: 4 },
        })
        .then(async res => {
          if (res.data.code == 0 && res.data.data != null) {
            const remarkList = await this.$axios.get("/misc-service/remark/listRemarkByRefNo", {
              params: { refNo: res.data.data.askNo },
            });
            this.printOrderList.push({
              ...res.data.data,
              remarkList: (remarkList?.data?.data || [])
                .filter(item => item.visibility === 0)
                .map(item => {
                  return {
                    ...item,
                    userTypeText: item.refUserNo.includes("SHYS")
                      ? "药师"
                      : item.refUserNo.includes("ZL")
                      ? "健康管家"
                      : item.refUserNo.includes("YS")
                      ? "医生"
                      : "",
                  };
                }),
            });
          } else {
            this.$respone.error(res.data.message);
          }
        })
        .catch(err => {
          this.$respone.error("网络异常，请稍后重试！");
        });
    },
    doPrintOrderDetail() {
      this.$nextTick(function () {
        const style = "@page {margin:0 10mm};";
        printJS({
          printable: "print_order_detail",
          type: "html",
          css: "/print.css",
          scanStyles: false,
        });
      });
    },
    doPrintLableDetail() {
      this.$nextTick(function () {
        printJS({
          printable: "print_lable_detail",
          type: "html",
          css: "/print4030.css",
          scanStyles: false,
        });
      });
    },
    batchPrintEOrder() {
      if (this.selectData.length != 0) {
        let orderNoList = [];
        let errorList = [];
        for (let i = 0; i < this.selectData.length; i++) {
          let item = this.selectData[i];
          if (!item.receiverName || !item.receiverAddress || item.deliverType == 2) {
            // 没有收货地址或自提
            errorList.push(`${item.orderNo}(${item.deliverType == 2 ? "自取" : "无收货地址"})`);
          } else {
            orderNoList.push(item.orderNo);
          }
        }
        if (errorList.length && !orderNoList.length) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `订单${errorList.join("，")}无法打印`,
            type: "warning",
          });
        } else if (orderNoList.length) {
          this.$confirm(
            "<p>" +
              (errorList.length ? `订单${errorList.join("，")}无法打印，` : "") +
              `确定${errorList.length ? "继续" : ""}打印 ${
                orderNoList.length
              } 张电子面单？</p><p style="color: #f00">请确认物流小哥能当天揽收再打印电子面单。</p>`,
            "批量打印电子面单",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
              dangerouslyUseHTMLString: true,
            }
          ).then(() => {
            this.doPrintEOrder(orderNoList);
          });
        } else {
          this.$message.warning("请选择待发货的订单");
          return;
        }
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "请先勾选需要打印电子面单的订单",
          type: "warning",
        });
      }
    },
    // 批量单元到付电子面单
    async batchPrintCollectOrder() {
      try {
        await this.$confirm(
          `您已选择打印【${this.selectData.length}】张到付的电子面单，通过到付电子面单邮寄的药品将在物流药品送达后由签收人支付物流费用（到付）。`,
          {
            title: "电子面单打印提示",
            confirmButtonText: "确定打印",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
          }
        );
        this.doPrintEOrder(
          this.selectData.map(row => row.orderNo),
          "CONSIGNEE"
        );
      } catch (error) {}
    },
    onDeliverConfirm(row) {
      // 确认自取
      this.$confirm("是否确认用户已取走商品？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "info",
      }).then(() => {
        let send = {};
        this.$axios
          .post("/order-service/drugmg/order/confirmReceipt/" + row.orderNo, send)
          .then(res => {
            if (res.data.code == 0) {
              this.$respone.success("用户自取成功！");
              this.getCommodityOrderList();
            } else {
              this.$respone.error(res.data.message);
            }
          })
          .catch(err => {
            this.$respone.error("网络异常，请稍后重试！");
          });
      });
    },
    printEOrder(index, row) {
      let orderNoList = [row.orderNo];
      this.$confirm(
        '<p>确定打印该张电子面单？</p><p style="color: #f00">请确认物流小哥能当天揽收再打印电子面单。</p>',
        "打印电子面单",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        this.doPrintEOrder(orderNoList);
      });
    },
    async doPrintEOrder(orderNoList, payType = null) {
      const loading = Loading.service({
        background: "rgba(0,0,0,.8)",
        text: "打印中，请等待...",
      });
      try {
        const res = await this.$axios.get(this.$APIURL.PRINT_E_LABEL, {
          params: {
            orderNoList: orderNoList.join(","),
            companyCode: this.expressType,
            printer: this.printState.printerSelectCode,
            payType,
          },
        });
        if (res.data.code == 0) {
          this.$respone.success("打印电子面单成功！");
          this.getCommodityOrderList();
        } else {
          this.$respone.error(res.data.message);
        }
      } catch (error) {
        this.$respone.error(error);
      }
      loading.close();
    },
    scanBarcode() {
      this.showScanBarDialog = true;
      this.$nextTick(function () {
        document.querySelector("#barcode_input").focus();
      });
    },
    async confirmLogistics() {
      if (this.retrievalLoading) {
        this.playaudio("audioLoad");
        this.scanBarCodeValue = "";
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.retrievalLoading = true;
      await this.$axios
        .get(this.$APIURL.CONFIRM_LOGISTICS, {
          params: { orderNo: this.scanBarCodeValue },
        })
        .then(res => {
          loading.close();
          //TODO 接口待调试
          if (res.data.code == 0) {
            this.retrievalLoading = false;
            this.playaudio("audioSuccess");
            this.$respone.success(this.scanBarCodeValue + " 确认订单发货成功！");
          } else if (res.data.code == 2) {
            this.retrievalLoading = false;
            this.playaudio("audioOut");
            this.$respone.success(this.scanBarCodeValue + res.data.message);
          } else {
            this.retrievalLoading = false;
            this.playaudio("audioFail");
            this.$respone.error(res.data.message);
          }
        })
        .catch(err => {
          this.retrievalLoading = false;
          this.playaudio("audioFail");
          this.$respone.error("网络异常，请稍后重试！");
          loading.close();
        });
      this.scanBarCodeValue = "";
      this.$nextTick(function () {
        document.querySelector("#barcode_input").focus();
      });
    },
    playaudio(id) {
      let audio = document.getElementById(id);
      audio.play();
    },
    onRemarksShow(row) {
      this.remarks.show = true;
      setTimeout(() => {
        this.$refs.remarks.init(row);
      });
    },
    onPrinterChange(code) {
      if (code) {
        window.localStorage.setItem(this.printState.printerKey, code);
      }
    },
    async getPrinterList() {
      try {
        const {
          data: { data },
        } = await this.$axios.get("/order-service/drugmg/order/printerList");
        this.printState.printerList = data || [];
        if (data.length > 0) {
          this.printState.printerSelectCode = data[0].value;
        }
        this.printState.printerSelectCode =
          window.localStorage.getItem(this.printState.printerKey) ||
          this.printState.printerSelectCode;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.onCycleGet();
    this.detailMap();
    this.getCommodityOrderList();
    this.orderStatus = this.$common.orderStatus;
    this.env = process.env.NODE_ENV;
    this.getPrinterList();
  },
};
</script>

<style scoped lang="scss">
:v-deep .el-input__inner {
  font-size: 0.14rem;
}

.el-dialog__wrapper {
  :v-deep .el-select {
    width: 100%;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0.3rem;
  font-size: 0.14rem;
  overflow-y: auto;
  background: #fbfbfb;
}

.pagination {
  margin-top: 0.2rem;
  text-align: center;
}

.btn_purple {
  background: #8080f7;
  border-color: #8080f7;
}

.barcode_input {
  border: 1px solid #333;
  padding: 0 15px;
  font-size: 0.6rem;
  width: 555px;
  height: 3em;
  line-height: 3em;
}

.advice-button {
  $color: #009999;
  background: $color;
  border-color: $color;
  &:hover {
    opacity: 0.85;
  }
  &:active {
    background: darken($color, 10%);
    border-color: darken($color, 10%);
  }
}
</style>
