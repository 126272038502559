var APIURL = {
  //图片
  PIC_SHOW: process.env.VUE_APP_PIC_SHOW,
  // LOGIN:'misc-service/admin/drugmg/login',
  LOGIN: "drug-service/admin/drugmg/login",
  //商品管理列表
  DRUGINFOS_ALL: "drug-service/drugmg/drugInfos/all",
  //商品订单列表
  ORDER_ITEMLIST: "order-service/drugmg/order/itemList",
  //商品详情
  DRUGINFOS_SELECTBYNO: "drug-service/drugInfos/selectByNo",
  //查看物流
  //LOOK_LOGISTIC: "order-service/drugmg/order/logisticShow",
  LOOK_LOGISTIC: "order-service/order/getLogisticsDetails",
  //商品订单详情
  LOOK_ORDER_DETAILS: "order-service/drugmg/order/itemDetail",
  //订单退货
  RETURN_ORDER_GOODS: "order-service/drugmg/order/return",
  //我的处方
  MY_PRESCRIPTION_LIST: "doctor-service/doctormg/prescription/doctormglist",
  //查看电子处方
  PRESCRIPTION_DETAILBYNO: "doctor-service/doctormg/prescription/detailByNo",
  // 查看处方单--new
  PRESCRIPTION_DETAILBYNO_PLUS: "doctor-service/doctormg/prescription/detailByNoList",
  // 获取签名图片
  DOCTOR_FINDBYDNO: "drug-service/examinePharmacisinfo/getByPrimarypharmacistNo",
  //每日订单数统计
  ORDER_COUNTORDERBYDAY: "order-service/drugmg/order/countOrderByDay",
  //每日订单数合计
  ORDER_COUNTORDERTOTAL: "order-service/drugmg/order/countOrderTotal",
  //药品销售数据
  DRUGINFOS_ITEMSALECOUNT: "order-service/drugmg/order/itemSaleCount",
  //首页顶部数据
  ORDER_TOP_COUNT: "order-service/drugmg/order/topIndex",
  //首页左边柱状图
  DRUGINFOS_ITEMCOUNTINDEX: "drug-service/drugmg/drugInfos/itemCountIndex",
  //添加物流信息（测试使用）
  // DRUGINFOS_ADDEXPRESS:'drug-service/drugmg/drugInfos/addExpress',
  //添加物流信息
  DRUGINFOS_ADDLOGISTIC: "order-service/order/addLogistic",
  // 审核处方
  DRUGINFOS_AUDITPRESCRIPTION: "doctor-service/doctormg/prescription/auditPrescriptionList",
  //再次发起流转
  DRUGINFOS_REINITIATEFLOW: "doctor-service/doctormg/prescription/reSync",
  // 获取物流信息
  //DRUGINFOS_DETAILMAP: "/misc-service/typeInfo/detailMap",
  DRUGINFOS_DETAILMAP: "/order-service/express-company/list",
  //文件上传
  UPLOAD_FILE: "file-service/file/uploadOther",
  // 添加物流前--判断是否可以添加物流
  LOGISTICS_ADD_HAS: "doctor-service/prescription/checkByPrescriptionNo",
  //LOGISTICS_ADD_HAS: "/order-service/express-company/list",
  // 根据订单编号查看处方
  getDoctorPrescriptionByOrderNo: "/doctor-service/prescription/getDoctorPrescriptionByOrderNo",
  // 根据处方编号查看原处方getInitPrescriptions
  INITO_PRESCRIPTIONS:
    "/doctor-service/admin/doctorservice/doctorInitPrescription/getInitPrescriptions",
  EXPORT_SELECT_ORDERLIST: "order-service/drugmg/order/exportSelectOrderList",
  CONFIRM_LOGISTICS: "order-service/drugmg/order/confirmLogistics",
  PRINT_E_LABEL: "order-service/drugmg/order/printELabel",
  CANCEL_E_LABEL: "doctor-service/drugmg/order/printEOrder",
};
export default APIURL;
